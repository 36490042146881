/* eslint-disable */
import Swiper from 'swiper/bundle'
import Cookieconsent from 'vanilla-cookieconsent'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import mapboxgl from 'mapbox-gl'
import jssocials from 'jssocials';
import jQB from 'jquery-bridget';
import Cookies from 'js-cookie';

export default {
    init() {
        // JavaScript to be fired on all pages
        let scroll = $(window).scrollTop();
        let windowWidth = $(window).width();
        let mapboxgl = require('mapbox-gl/dist/mapbox-gl.js');
        mapboxgl.accessToken = 'pk.eyJ1IjoiYmNuY28iLCJhIjoiY2wxeHEyNm5xMDBvOTNjcmE4bHRzZHJyeiJ9.d4ATsz8VW2qpcDG-Ic9Sbg';

        $(function () {

            if ($('#addToCal').length > 0) {
                new atc(document.querySelector('#addToCal'), {
                    labels: false,
                    timezone: "Europe/Paris",
                })
            }

            $(window).scroll(function () {
                scroll = $(window).scrollTop();
                posHeader();
            });

            $(window).resize(function () {
                windowWidth = $(window).width();
            });


            // Blur on click
            $('a, button, input[type=\'submit\'], .btn').on('click', function () {
                $(this).blur();
            });


            // Button scroll down
            $('.js_btn-scroll-down').on('click', function () {
                $('html,body').stop().animate({
                    scrollTop: $(this).closest('.module').offset().top + $(this).closest('.module').outerHeight(true),
                }, 1000);
            });


            // ========================================
            // Header
            // ========================================
            $('.js_toggle-menu').on('click', function () {
                if (windowWidth <= 1024) {
                    $(this).toggleClass('close');
                    $('html').toggleClass('disable-scroll');
                    $('body').toggleClass('menu-active');
                }
            });

            $('#header .menu-primary .menu > li.menu-item-has-children > a').on('click', function (e) {
                if (windowWidth <= 1024) {
                    e.preventDefault();
                    let $elt = $(this);
                    $elt.closest('li').siblings('li').find('a').removeClass('active');
                    $elt.closest('li').siblings('li').find('.sub-menu').slideUp(400);
                    $elt.toggleClass('active').next('.sub-menu').slideToggle(400);
                }
            });

            $('.js_btn-toggle-search').on('click', function () {
                $('body').toggleClass('search-active');
                $('html').toggleClass('disable-scroll');
                setTimeout(function () {
                    if ($('body').hasClass('search-active')) {
                        $('.search-form .search-field').focus();
                    }
                }, 100);
            });

            let position = $(window).scrollTop();
            let keepHeaderVisible = false;
            let headerSticky = 0;
            let $header = $('#header');
            let headerHeight;
            let headerTop;
            let $headerContent = jQuery('.header-page-content');

            function posHeader() {
                headerHeight = $header.outerHeight(true);
                headerTop = $('#header').offset().top;

                if (scroll <= headerTop) {
                    $('body').removeClass('scroll-up scroll-down');
                } else if (scroll > headerTop) {
                    $('body').addClass('scroll-down').removeClass('scroll-up');
                } else {
                    $('body').addClass('scroll-up').removeClass('scroll-down');
                }

                if ($(document).width() > 1) {
                    if (scroll > position) {
                        // Scroll down
                        if (scroll > headerHeight + headerTop) {
                            // Cache le header
                            headerSticky = headerHeight + headerTop;
                            $header.addClass('transition');
                        } else {
                            if (!keepHeaderVisible) {
                                // Cache le header progressivement suivant le scroll
                                headerSticky = scroll;
                                $header.removeClass('transition');
                            }
                        }
                    } else {
                        // Scroll Up
                        if (scroll > headerHeight + headerTop) {
                            // Affiche le header
                            headerSticky = headerTop;
                            $header.addClass('transition');
                            keepHeaderVisible = true; // Garde le header visible jusqu'en haut de page
                        } else if (keepHeaderVisible && scroll > headerTop) {
                            headerSticky = headerTop;
                            $header.addClass('transition');
                        } else {
                            // Affiche le header progressivement suivant le scroll
                            headerSticky = scroll;
                            $header.removeClass('transition');
                            keepHeaderVisible = false; // Garde le header visible jusqu'en haut de page
                        }
                    }
                    if (scroll <= 0) {
                        headerSticky = 0;
                        keepHeaderVisible = false;
                    }

                    $('#header .header-wrapper').css('transform', 'translateY(-' + headerSticky + 'px)');

                    position = scroll;
                } else {
                    $('#header .header-wrapper').css('transform', '');
                }
            }

            posHeader();


            // ========================================
            // Footer
            // ========================================
            $('.js_footer-btn-scroll').on('click', function () {
                $('html,body').stop().animate({
                    scrollTop: 0,
                }, 1500);
            });


            // ========================================
            // Modules
            // ========================================
            // Module Hero
            if ($('.module-hero .item').length > 1) {
                new Swiper('.js_slider-hero', {
                    loop: true,
                    speed: 1000,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.js_slider-hero-btn-next',
                        prevEl: '.js_slider-hero-btn-prev',
                    },
                });
            }


            // Module News slider
            $('.js_news-slider').each(function (index) {
                let elt = this;
                $(elt).siblings('.js_news-slider-btn-prev, .js_news-slider-btn-next').addClass('index-' + index);

                let params = {
                    loop: true,
                    speed: 750,
                    navigation: {
                        nextEl: '.js_news-slider-btn-next.index-' + index,
                        prevEl: '.js_news-slider-btn-prev.index-' + index,
                    },
                };

                if ($(elt).hasClass('columns-2')) {
                    params.spaceBetween = 26;
                    params.slidesPerView = 1;
                    params.breakpoints = {
                        601: {
                            slidesPerView: 2,
                        },
                    };
                } else if ($(elt).hasClass('columns-3')) {
                    params.spaceBetween = 26;
                    params.slidesPerView = 1;
                    params.breakpoints = {
                        769: {
                            slidesPerView: 2,
                        },
                        1201: {
                            slidesPerView: 3,
                        },
                    };
                } else {
                    params.spaceBetween = 26;
                    params.slidesPerView = 'auto';
                }

                new Swiper(elt, params);

            });


            // Module Logos slider
            $('.js_logos-slider').each(function (index) {
                let elt = this;
                $(elt).siblings('.js_logos-slider-btn-prev, .js_logos-slider-btn-next').addClass('index-' + index);
                let params = {
                    // loop: true,
                    speed: 750,
                    slidesPerView: 2,
                    spaceBetween: 20,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: true,
                    },
                    navigation: {
                        nextEl: '.js_logos-slider-btn-next.index-' + index,
                        prevEl: '.js_logos-slider-btn-prev.index-' + index,
                    },
                    breakpoints: {
                        481: {
                            slidesPerView: 3,
                        },
                        601: {
                            slidesPerView: 4,
                        },
                        961: {
                            slidesPerView: 5,
                            spaceBetween: 26,
                        },
                        1025: {
                            slidesPerView: 6,
                        },
                    },
                }
                if (windowWidth > 1024) {
                    if ($(elt).find('.item').length > 6) {
                        new Swiper(elt, params);
                    }
                } else if (windowWidth > 960) {
                    if ($(elt).find('.item').length > 5) {
                        new Swiper(elt, params);
                    }
                } else if (windowWidth > 600) {
                    if ($(elt).find('.item').length > 4) {
                        new Swiper(elt, params);
                    }
                } else if (windowWidth > 480) {
                    if ($(elt).find('.item').length > 3) {
                        new Swiper(elt, params);
                    }
                } else {
                    if ($(elt).find('.item').length > 2) {
                        new Swiper(elt, params);
                    }
                }
            });


            // Module Accordion
            $('.js_toggle-accordion').on('click', function () {
                let $elt = $(this);
                let speed = 400;
                if ($elt.hasClass('active')) {
                    $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
                } else {
                    $elt.closest('.accordion').find($('.title')).removeClass('active').next('.txt').slideUp(speed);
                    $elt.addClass('active').next('.txt').slideDown(speed);
                }
            });


            // Module Images slider
            let sliderImage = [];
            $('.js_images-slider').each(function (index) {
                let elt = this;
                let nbrSlides = 0;



                let space = 20;
                if ($(elt).find('.item').length > 1) {
                    $(elt).data('index', index); // Utilisé pour la popin du module gallery
                    $(elt).siblings('.js_images-slider-btn-prev, .js_images-slider-btn-next, .js_images-slider-pagination').addClass('index-' + index);


                  let params = {
                    loop: true,
                    speed: 750,
                    navigation: {
                      nextEl: '.js_images-slider-btn-next.index-' + index,
                      prevEl: '.js_images-slider-btn-prev.index-' + index,
                    },
                    pagination: {
                      el: '.js_images-slider-pagination.index-' + index,
                      type: 'bullets',
                      clickable: true,
                    },
                    slidesPerView: nbrSlides,
                    spaceBetween: space,
                    breakpoints : {
                      200:{
                        slidesPerView: ($(elt).hasClass('columns-3')?1:1),
                      },
                      769: {
                        slidesPerView: ($(elt).hasClass('columns-3')?2:1),
                      },
                      1201: {
                        slidesPerView: ($(elt).hasClass('columns-3')?3:1),
                      },
                    },
                  };
                    sliderImage[index] = new Swiper(elt, params);
                }
            });


            // Module Video
            $('.js_btn-video').on('click', function () {
                let $elt = $(this);
                let $video = $elt.closest('.video');
                let $iframe = $video.find('iframe');
                let src = 'https://www.youtube-nocookie.com/embed/' + $elt.data('src') + '?rel=0&showinfo=0&enablejsapi=1&autoplay=1';
                $iframe.attr('src', src);
                $video.addClass('active');
                setTimeout(function () {
                    $iframe[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                }, 600);
            });


            // Module References
            $('.js_references-slider').each(function () {
                let elt = this;
                if ($(elt).find('.sub-item').length > 1) {
                    new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        allowTouchMove: false,
                        navigation: {
                            nextEl: '.js_references-slider-btn-next',
                            prevEl: '.js_references-slider-btn-prev',
                        },
                        pagination: {
                            el: '.js_references-slider-pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });
            $('.js_references-popin-slider').each(function () {
                let elt = this;
                if ($(elt).find('.item').length > 1) {
                    new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        navigation: {
                            nextEl: '.js_references-popin-slider-btn-next',
                            prevEl: '.js_references-popin-slider-btn-prev',
                        },
                        pagination: {
                            el: '.js_references-popin-slider-pagination',
                            type: 'bullets',
                            clickable: true,
                        },
                    });
                }
            });


            // Module Map
            $('.js_load-map').each(function () {
                let elt = this;
                let markers = $(elt).attr('data-markers');
                const map = new mapboxgl.Map({
                    container: $(elt).attr('id'),
                    style: 'mapbox://styles/mapbox/streets-v11',
                    center: [$(elt).attr('data-lng'), $(elt).attr('data-lat')],
                    zoom: 16,
                });
                // map.scrollZoom.disable();

                var coor = jQuery.parseJSON(markers);
                var bounds = new mapboxgl.LngLatBounds();

                coor.forEach(function (key) {
                    const el = document.createElement('div');
                    const containerLogo = document.createElement('div')
                    containerLogo.setAttribute('class', 'container-pin');

                    const logo = document.createElement("IMG");
                    logo.setAttribute('class', 'img-pin');
                    logo.src = $(elt).attr('data-pin');

                    el.className = 'marker';
                    containerLogo.append(logo);
                    el.append(containerLogo);
                    new mapboxgl.Marker(el)
                        .setLngLat([key[0], key[1]])
                        .addTo(map);

                    bounds.extend(key);
                });

                // permet d'ajuster le zoom en fonction des markers
                map.fitBounds(bounds, {padding: 100});
                var mapLat = map.getBounds().getCenter().lat;
                var mapLong = map.getBounds().getCenter().long;
                map.setCenter = (mapLat, mapLong);
                if (coor.length == 1) {
                    var zoomAjust = 1;
                    var mapZoom = map.getZoom();
                    var newZoom = mapZoom - zoomAjust;
                    map.setZoom(newZoom);
                }
            });


            // Module Text video slider
            let sliderTextVideo = [];
            $('.js_text-video-slider').each(function (index) {
                let elt = this;
                if ($(elt).find('.item').length > 1) {
                    $(elt).siblings('.js_text-video-slider-btn-prev, .js_text-video-slider-btn-next').addClass('index-' + index);
                    $(elt).find('.js_text-video-slider-btn-prev, .js_text-video-slider-btn-next').addClass('index-' + index);
                    sliderTextVideo[index] = new Swiper(elt, {
                        loop: true,
                        speed: 750,
                        navigation: {
                            nextEl: '.js_text-video-slider-btn-next.index-' + index,
                            prevEl: '.js_text-video-slider-btn-prev.index-' + index,
                        },
                    });
                }
            });


            // Module Share
            var url = "http://google.com";
            var text = "Some text to share";

            $('#share').jsSocials({
                showLabel: false,
                showCount: false,
                shares: ["email", "twitter", "facebook", "linkedin", "pinterest", "whatsapp", "messenger"]
            });


            // ========================================
            // Popin
            // ========================================
            function openPopin(popin, slide) {
                $('.popin-wrapper[data-popin="' + popin + '"]').show(0).addClass('active');
                $('html').addClass('disable-scroll');
                if (slide) {
                    sliderImage[$('.popin-wrapper[data-popin="' + popin + '"]').find('.slider').data('index')].slideTo(slide);
                }
            }

            function closePopin(popin) {
                if (popin) {
                    $('.popin-wrapper[data-popin="' + popin + '"]').removeClass('active');
                    setTimeout(function () {
                        $('html').removeClass('disable-scroll');
                        $('.popin-wrapper[data-popin="' + popin + '"]').hide(0);
                    }, 600);
                } else {
                    $('.popin-wrapper').removeClass('active');
                    setTimeout(function () {
                        $('html').removeClass('disable-scroll');
                        $('.popin-wrapper').hide(0);
                    }, 600);
                }
            }

            $(document).on('click', '.js_open-popin', function () {
                openPopin($(this).data('popin'), $(this).data('slide'));
            });


            $(document).on('click', '.js_close-popin', function () {
                closePopin();
            });
            $(document).on('click', '.popin-wrapper', function (e) {
                if (e.target !== this) {
                    return;
                }
                closePopin();
            });


            // ========================================
            // Popin Alert
            // ========================================
            $(document).on('click', '.js_close-popin-alert', function () {
                sessionStorage['alerte'] = 'alertClose';
            });

            $(document).on('click', '.popin-alert-wrapper', function (e) {
                if (e.target !== this) {
                    return;
                }
                sessionStorage['alerte'] = 'alertClose';
            });
            let alerte = $('.popin-alert-wrapper');
            let data = sessionStorage['alerte'];
            if (data === 'alertClose') {
                closePopin('alert');
            } else if (alerte.length > 0) {
                openPopin('alert');
            }


            // ========================================
            // Animations
            // ========================================
            gsap.registerPlugin(ScrollTrigger);


            // Main
            gsap.to('#main', {duration: .7, opacity: 1});


            // Pour activer / désactiver les animations
            if (!$('body').hasClass('woocommerce')) {
                $('.module.has-background-image.parallax').each(function (index, elt) {
                    gsap.to($(elt).find('.background'), {
                        yPercent: 25,
                        ease: "none",
                        scrollTrigger: {
                            trigger: elt,
                            scrub: true
                        },
                    });
                });


                // Module Hero - list
                $('.module-hero.mode-list').each(function (index, elt) {
                    gsap.from($(elt).find('.item:nth-child(1)'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: -75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.item:nth-child(2)'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: 75,
                        opacity: 0,
                    });
                });


                // Module Posts list
                $('.module-posts-list').each(function (index, elt) {
                    let tlModulePostList = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModulePostList
                        .from($(elt).find('.title-module'), .7, {y: 25, opacity: 0})
                        .from($(elt).find('.item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Module News slider
                $('.module-news-slider').each(function (index, elt) {
                    let tlModuleNewsSlider = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleNewsSlider
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.btn-more-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Text image
                $('.module-text-image').each(function (index, elt) {
                    gsap.from($(elt).find('.img-left .col-1'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: -75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.img-left .col-2'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: 75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.img-right .col-1'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: 75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.img-right .col-2'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        x: -75,
                        opacity: 0,
                    });
                });


                // Module Text
                $('.module-text').each(function (index, elt) {
                    let tlModuleText = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleText
                        .from($(elt).find('.txt'), {duration: .7, y: 75, opacity: 0})
                        .from($(elt).find('.btn-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Head text
                $('.module-head-text').each(function (index, elt) {
                    gsap.from($(elt).find('.col'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 75,
                        opacity: 0,
                        stagger: 0.1,
                    });
                });


                // Module Logos slider
              $('.module-logos-slider').each(function (index, elt) {
                if (!$(elt).hasClass("module-footer")) {
                  let tlModuleLogosSlider = gsap.timeline({
                    scrollTrigger: {
                      trigger: elt,
                      start: 'top 100%',
                    },
                  });
                  tlModuleLogosSlider
                    .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                    .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                }
              });

                // Module Contact
                $('.module-contact').each(function (index, elt) {
                    let tlModuleContact = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleContact
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.col-1'), {duration: .7, x: -75, opacity: 0}, '-=.5')
                        .from($(elt).find('.col-2'), {duration: .7, x: 75, opacity: 0}, '-=.7')
                });


                // Module Form
                $('.module-form').each(function (index, elt) {
                    let tlModuleForm = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleForm
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.gfield'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                        .from($(elt).find('.gform_footer'), {duration: .7, y: 25, opacity: 0})
                });


                // Module Map
                $('.module-map').each(function (index, elt) {
                    let tlModuleMap = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleMap
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.map-wrapper'), {duration: .7, opacity: 0}, '-=.5')
                });


                // Module Accordion
                $('.module-accordion').each(function (index, elt) {
                    let tlModuleAccordion = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleAccordion
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.title'), {
                            duration: .7,
                            x: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Module Images slider
                $('.module-images-slider:not(.popin-wrapper .module-images-slider)').each(function (index, elt) {
                    let tlModuleImagesSlider = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleImagesSlider
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Vidéo
                $('.module-video').each(function (index, elt) {
                    let tlModuleVideo = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleVideo
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.video'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Quote
                $('.module-quote').each(function (index, elt) {
                    let tlModuleQuote = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleQuote
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.txt'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.illus-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                        .from($(elt).find('.name'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                        .from($(elt).find('.function'), {duration: .7, y: 75, opacity: 0}, '-=.6')
                });


                // Module References
                $('.module-references').each(function (index, elt) {
                    let tlModuleReferences = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleReferences
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.tabs'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                        .from($(elt).find('.btn-more-wrapper'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                });


                // Module Gallery
                $('.module-gallery').each(function (index, elt) {
                    let tlModuleGallery = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleGallery
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.tabs'), {duration: .7, y: 75, opacity: 0}, '-=.5')
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.6')
                });


                // Module Icons
                $('.module-icons').each(function (index, elt) {
                    let tlModuleIcons = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleIcons
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Module Key figures
                $('.module-key-figures').each(function (index, elt) {
                    let tlModuleKeyFigures = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleKeyFigures
                        .from($(elt).find('.title-module'), {duration: .7, y: 25, opacity: 0})
                        .from($(elt).find('.list .item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')

                    gsap.to($(elt).find('.nbr'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        innerText: function (index, elt) {
                            return elt.dataset.nbr
                        },
                        snap: {innerText: 1},
                        stagger: 0.1,
                        duration: 1,
                    });
                });


                // Module Link
                $('.module-link').each(function (index, elt) {
                    let tlModuleLink = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleLink
                        .from($(elt).find('.btn-wrapper'), {duration: .7, y: 75, opacity: 0})
                });


                // Module Text video slider
                $('.module-text-video-slider').each(function (index, elt) {
                    let tlModuleTextVideoSlider = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleTextVideoSlider
                        .from($(elt).find('.slider-wrapper'), {duration: .7, y: 75, opacity: 0})
                });


                // Module Clickable
                $('.module-clickable .items').each(function (index, elt) {
                    console.log(elt)
                    let tlModuleClickable = gsap.timeline({
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                    });
                    tlModuleClickable
                        .from($(elt).prev('.title-module'), .7, {y: 25, opacity: 0})
                        .from($(elt).find('.item'), {
                            duration: .7,
                            y: 75,
                            opacity: 0,
                            stagger: 0.1,
                        }, '-=.5')
                });


                // Page Archive news
                $('.page-archive-news').each(function (index, elt) {
                    gsap.from($(elt).find('.title-page-wrapper'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 25,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.tabs'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 75,
                        opacity: 0,
                    });
                    gsap.from($(elt).find('.list-single .item'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 75,
                        opacity: 0,
                        stagger: 0.1,
                    });
                });


                // Page Archive references
                $('.page-archive-references').each(function (index, elt) {
                    gsap.from($(elt).find('.title-page-wrapper'), {
                        scrollTrigger: {
                            trigger: elt,
                            start: 'top 80%',
                        },
                        duration: .7,
                        y: 25,
                        opacity: 0,
                    });
                });
            }


            // Loader
            if ($('.loader').length > 0) {
                gsap.to('.loader img', {duration: 1.1, y: -75, ease: 'power1.inOut'}, '+1.5');
                gsap.to('.loader', {duration: .9, opacity: 0}, '+1.8');
                gsap.to('.loader', {duration: 0, className: 'loader disable'}, '+2.8');
            }


            // Event Toogle
            let toggleButton = document.getElementById("eventToggleButton");
            let main = document.querySelector('main');
            if (toggleButton) {
                toggleButton.addEventListener("click", function () {
                    if (jQuery('main').hasClass('event-style-list')) {
                        jQuery(main).removeClass('event-style-list');
                        main.classList.add('event-style-grid');
                    } else if (jQuery('main').hasClass('event-style-grid')) {
                        jQuery(main).removeClass('event-style-grid');
                        main.classList.add('event-style-list');
                    }
                });
            }

            // Gtranslate text adding
            $('.gglobe').each(function (index, elt) {

                let gglobeIcon = document.createElementNS("http://www.w3.org/2000/svg", 'svg');
                gglobeIcon.setAttribute("version", "1.1");
                gglobeIcon.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                gglobeIcon.setAttribute("width", "16px");
                gglobeIcon.setAttribute("height", "16px");
                gglobeIcon.setAttribute("viewBox", "0 0 16 16");

                let gglobeIconPath = document.createElementNS("http://www.w3.org/2000/svg", 'path')
                gglobeIconPath.setAttribute("d", "M8.3,1.5L7.3,2.3C7.1,2.4,7,2.6,7,2.8v0.3c0,0.2,0.2,0.4,0.4,0.4c0.1,0,0.2,0,0.2-0.1l1.3-0.9C9,2.5,9,2.5,9.1,2.5h0 c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.2-0.1,0.2L8.8,3.7C8.6,3.9,8.4,4,8.1,4.1L7.3,4.4C7.1,4.5,7,4.6,7,4.8C7,4.9,7,5,6.9,5.1L6.3,5.7 C6.1,5.9,6,6.2,6,6.4v0.1c0,0.5,0.4,0.9,0.9,0.9c0.3,0,0.7-0.2,0.8-0.5l0.1-0.3C8,6.6,8.1,6.5,8.3,6.5c0.1,0,0.3,0.1,0.4,0.2 l0.5,0.7c0.1,0.1,0.2,0.1,0.3,0.1c0.3,0,0.4-0.3,0.3-0.5l0-0.1c-0.1-0.2,0-0.5,0.2-0.6l0.7-0.2C10.8,6.1,11,5.8,11,5.6 C11,5.3,11.3,5,11.6,5h0.9C12.8,5,13,5.2,13,5.5S12.8,6,12.5,6h-0.6c-0.2,0-0.4,0.1-0.6,0.2l-0.1,0.1C11,6.5,11,6.6,11,6.6 C11,6.8,11.2,7,11.4,7h0.4c0.2,0,0.4,0.1,0.5,0.2l0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2s0,0.2-0.1,0.2l-0.2,0.2C12.1,8.2,12,8.3,12,8.5 s0.1,0.3,0.2,0.4l0.6,0.6C13.1,9.8,13.5,10,14,10h0.2c0.2-0.6,0.3-1.3,0.3-2C14.5,4.5,11.8,1.7,8.3,1.5z M13.7,11.1 C13.6,11,13.4,11,13.3,11c-0.2,0-0.4-0.1-0.5-0.2l-0.4-0.4c-0.2-0.2-0.6-0.4-0.9-0.4c-0.3,0-0.6-0.1-0.8-0.3L9.8,9 C9.4,8.7,9,8.5,8.5,8.5H7.9c-0.4,0-0.8,0.1-1.1,0.3L5.9,9.4C5.3,9.8,5,10.4,5,11.1v0.1c0,0.5,0.2,1,0.6,1.4l0.5,0.5 c0.3,0.3,0.6,0.4,1,0.4h0.7c0.4,0,0.8,0.3,0.8,0.8c0,0.1,0,0.2,0,0.2C10.8,14.3,12.7,13,13.7,11.1z M0,8c0-4.4,3.6-8,8-8s8,3.6,8,8 s-3.6,8-8,8S0,12.4,0,8z M5.9,3.1C5.7,3,5.3,3,5.1,3.1l-1,1C4,4.3,4,4.7,4.1,4.9s0.5,0.2,0.7,0l1-1C6,3.7,6,3.3,5.9,3.1z");
                gglobeIcon.appendChild(gglobeIconPath);

                elt.appendChild(gglobeIcon);

                let gglobeText = document.createElement('span');
                gglobeText.textContent = 'Langues';
                elt.appendChild(gglobeText);
            });

            // ========================================
            // Cookie
            // ========================================
            let cc = initCookieConsent();
            const setMapCookie = function (cookie) {
                let cooks = Cookies.get();
                if (cookie.categories && cookie.categories.includes('advertising')) {
                    if (!cooks.map) {
                        Cookies.set('map', 1, {expires: 182, path: '/'});
                    }
                } else {
                    if (cooks.map) {
                        Cookies.remove('map', {path: '/'});
                    }
                }
                return true;
            }

            // run plugin with your configuration
            cc.run({
                current_lang: 'fr',

                gui_options: {
                    consent_modal: {
                        layout: 'box',              // box/cloud/bar
                        position: 'bottom left',    // bottom/middle/top + left/right/center
                        transition: 'slide',        // zoom/slide
                    },
                    settings_modal: {
                        layout: 'box',              // box/bar
                        // position : 'left',       // left/right
                        transition: 'slide',        // zoom/slide
                    },
                },
                // si les cookies sont acceptés (ne pas confondre avec le fait de cliquer sur accepter)
                onAccept: function (cookie) {
                    //console.log(cooks);
                    //setMapCookie(cookie);
                    // document.querySelector('.cookies-button').classList.add('is-visible');
                },
                onFirstAction: function (cookie) {
                    window.location.reload();
                },
                onChange: function (cookie) {
                    setMapCookie(cookie);
                    window.location.reload();
                },

                languages: {
                    'fr': {
                        consent_modal: {
                            title: 'Hello ! voici des <span class="large">Cookies</span>',
                            description: 'Ce site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec lui. Ce dernier ne sera fixé qu\'après accord. <button type="button" data-cc="c-settings" class="cc-link">Choisir mes préférences</button>',
                            primary_btn: {
                                text: 'Tout accepter',
                                role: 'accept_all',             // 'accept_selected' or 'accept_all'
                            },
                            secondary_btn: {
                                text: 'Tout rejeter',
                                role: 'accept_necessary',        // 'settings' or 'accept_necessary'
                            },
                        },
                        settings_modal: {
                            title: 'Gestion de préférence des cookies',
                            save_settings_btn: 'Enregistrer',
                            accept_all_btn: 'Tout accepter',
                            reject_all_btn: 'Tout rejeter',
                            close_btn_label: 'Fermer',
                            cookie_table_headers: [
                                {col1: 'Nom'},
                                {col2: 'Domaine'},
                                {col3: 'Expiration'},
                                {col4: 'Description'},
                            ],
                            blocks: [
                                {
                                    // title: 'Cookie usage 📢',
                                    description: 'Sur ce site, nous utilisons des cookies pour mesurer notre audience. Vous pouvez sélectionner ici les services que vous allez autoriser.',
                                }, {
                                    title: 'Cookies nécessaires au bon fonctionnement du site',
                                    description: 'Ces cookies sont indispensables au bon fonctionnement du site internet. Ces cookies sont essentiels au bon fonctionnement du site, vous ne pouvez pas les désactiver.',
                                    toggle: {
                                        value: 'necessary',
                                        enabled: true,
                                        readonly: true,
                                    },
                                }, {
                                    title: 'Publicitaires (ou presque)',
                                    description: 'Pour concevoir ce site nous avons fait appel à une société bien de chez nous (à Nîmes pour être précis) et cette société s’appuie pour rendre votre site plus dynamique sur des technologies tierces : YouTube, openmap, etc… Pour profiter de l’ensemble des technologies nous vous invitons à accepter ces cookies.',
                                    toggle: {
                                        value: 'advertising',
                                        enabled: false,
                                        readonly: false,
                                    },
                                }, {
                                    title: 'Google Analytics',
                                    description: 'Ces cookies permettent d\'analyser les statistiques de consultation de notre site.',
                                    toggle: {
                                        value: 'analytics',
                                        enabled: false,
                                        readonly: false,
                                    },
                                    // cookie_table: [             // list of all expected cookies
                                    //     {
                                    //         col1: '^_ga',       // match all cookies starting with "_ga"
                                    //         col2: 'google.com',
                                    //         col3: '2 years',
                                    //         col4: 'description ...',
                                    //         is_regex: true
                                    //     },
                                    //     {
                                    //         col1: '_gid',
                                    //         col2: 'google.com',
                                    //         col3: '1 day',
                                    //         col4: 'description ...',
                                    //     }
                                    // ]
                                    // }, {
                                    //     title: 'Advertisement and Targeting cookies',
                                    //     description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                                    //     toggle: {
                                    //         value: 'targeting',
                                    //         enabled: false,
                                    //         readonly: false
                                    //     }
                                    // }, {
                                    //     title: 'More information',
                                    //     description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                                },
                            ],
                        },
                    },
                },
            });

            //Gestion de la suppression eventuelle du cookie map mais pas du cookie cc_cookie et vice versa
            let ccName = cc.getConfig('cookie_name');
            let ccCookie = Cookies.get(ccName);
            let mapCookie = Cookies.get('map');
            if (mapCookie !== undefined && ccCookie === undefined) {
                Cookies.remove('map', {path: '/'});
                window.location.reload();
            }
            if (ccCookie !== undefined && mapCookie === undefined) {
                let cookie = JSON.parse(ccCookie);
                if (cookie.categories && cookie.categories.includes('advertising')) {
                    console.log('yea');
                    Cookies.set('map', 1, {expires: 182, path: '/'});
                    window.location.reload();
                }
            }

        });
    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
