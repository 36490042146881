/* eslint-disable */
(function ($) {
    var AjaxActu = function (item) {
        this.$container = item.find('.js_actu-container');
        this.$term = item.find('.js_change-term');
        this.$pagination = item.find('.js_list-pagination');
        this.$list = item.find('.js_list-actu');
        this.params = [];
        this.params.ajaxUrl = ajaxObject.ajaxurl;
        this.params.ajaxActionChange = 'change';
        this.params.ajaxActionLoadmore = 'loadmore';
        this.params.ajaxMethod = 'news';

        this.init();
    };

    AjaxActu.prototype.init = function () {
        var self = this;

        $(document).on('click', '.js_change-term', function (e) {
            e.stopImmediatePropagation();
            $('.js_change-term').removeClass('active');
            $(this).addClass('active');
            self.change($(this).attr('data-value'));
        })
        // self.$term.on('change', function (e) {
        //     self.change($(self.$term).val());
        // });

        $(document).on('click', '.js_load-more', function (e) {
            e.stopImmediatePropagation();
            self.loadmore($(self.$term).val(), $('.js_load-more').attr('data-page'));
        })
    };


    AjaxActu.prototype.change = function (term) {
        var self = this;

        $.ajax({
            url: self.params.ajaxUrl,
            method: 'POST',
            data: {
                action: self.params.ajaxActionChange,
                method: self.params.ajaxMethod,
                term: term
            },
            dataType: 'json',
            success: function (response) {
                self.$list.empty();
                self.$list.append(response.data.html);
                self.$pagination.empty();
                self.$pagination.html(response.data.pagination);
            },
        }).fail(function (textStatus, errorThrown) {
            console.log(textStatus);
            console.log(errorThrown);
        });
    };


    AjaxActu.prototype.loadmore = function (term, targetPage = 0) {
        var self = this;

        $.ajax({
            url: self.params.ajaxUrl,
            method: 'POST',
            data: {
                action: self.params.ajaxActionLoadmore,
                method: self.params.ajaxMethod,
                term: term,
                targetPage: targetPage,
            },
            dataType: 'json',
            success: function (response) {
                self.$list.append(response.data.html);
                self.$pagination.empty();
                self.$pagination.html(response.data.pagination);
            },
        }).fail(function (textStatus, errorThrown) {
            console.log(textStatus);
            console.log(errorThrown);
        });
    };
    $(document).ready(function () {

        if ($('.js_actu-container').length > 0) {
            new AjaxActu($(this));
        }
    });
})(jQuery);

